.name-text-element {
    width: 100%;
    background-color: lightskyblue;
}

.name-element {
    width: 100%;
}

.name-checkboxs {
    margin-bottom: 1rem!important;
}