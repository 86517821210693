.notification-element-text {
    min-width: 200px;
}

.notifications {
    display: block !important;
    margin-bottom: 1rem!important;
}

.notification-title {
    width: 100%;
    background-color: lightskyblue; 
}